<template>
    <div v-if="authUserPermission['activity-log-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col-3">
                            <h3 class="mb-0">{{ tt('activity_log') }}</h3>
                        </div>
                        <div class="col-2 text-right">
                            <!-- <input type="button" class="btn btn-sm btn-outline-dark" value="Export as Excel" disabled="true" v-if="btnExport.disabled"> -->
                            <a v-if="authUserPermission['activity-log-export']" :href="apiUrl+'ActivityLog/export?date='+inputActivityLog.date+'&token='+token" class="btn btn-sm btn-outline-dark" v-else>Export as Excel</a>
                        </div>
                        <div class="col-3">
                            <input class="form-control form-control-sm text-center border-radius-20" input-classes="form-control-sm" v-model="inputActivityLog.search" :placeholder="tt('search_activity_log')" @keyup="filter"/>
                        </div>
                        <div class="col-3">
                            <base-input type="date" input-classes="form-control-sm border-radius-20" v-model="inputActivityLog.date" :placeholder="tt('date')"/>
                        </div>
                        <div class="col-1 text-right">
                            <i class="fa fa-filter btn btn-sm btn-default" @click="search(inputActivityLog.date == undefined ? dateNow : inputActivityLog.date)"></i>
                        </div>
                    </div>
                </div>
                <div v-if="!onLoad">
                    <div v-if="!table.show" style="height: 500px;display: flex;justify-content: center;align-items: center;flex-direction: column">
                        <img src="/img/smartcat/data_is_empty.png" alt="Data Is Empty" style="height: 320px;width: 340px">
                        <p class="text-muted">{{ tt('no_activity_log_in_smartcat') }}</p>
                    </div>
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="dataActivityLog" v-else>
                        <el-table-column :label="tt('id')" :prop="tt('id')" min-width="140px" sortable>
                            <template v-slot="{row}">
                                {{row.id}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('user')" :prop="tt('user')" min-width="140px" sortable>
                            <template v-slot="{row}">
                                {{row.user}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('method')" :prop="tt('method')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.method}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('url')" :prop="tt('url')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.url}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('ip')" :prop="tt('ip')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.ip}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('user_agent')" :prop="tt('user_agent')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.user_agent}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.created_at}}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <skeleton-loading v-else/>
                <div class="card-footer">
                    <span class="text-muted float-right">
                        <small>{{ tt('this_activity_log_has_been_taken_today') }}</small>
                    </span>
                </div>
            </div>
        </div>
<!--         <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="inputActivityLog.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" rules="required">
                    <el-select class="select-danger" v-model="inputActivityLog.table" placeholder="Choose Table">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('column') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('column')" rules="required">
                    <el-select class="select-danger" v-model="inputActivityLog.column" placeholder="Choose Column">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal> -->
    </div>
    <noaccess v-else/>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import activityLog from '@/services/report/activityLog.service';
    import baseApiUrl from '@/configs/config';

    export default {        
        data() {
            return {       
                onLoad: false,         
                form: {
                    add: true,
                    title: this.tt('add_table_column'),
                    show: false
                }, 
                table: {
                    show: true
                },
                btnExport: {
                    disabled: true
                },
                inputActivityLog: {
                    date: '',
                    search: (this.$route.query.search) ? this.$route.query.search : ''
                },
                dataActivityLog: [
                    {
                        ip: '',
                        log_code: '',
                        note: ''
                    }
                ],
                apiUrl: baseApiUrl.apiUrl,
                token: localStorage.token,
                dateNow: ''
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            let date = new Date();
            let month = date.getMonth()+1;
            if (month == 0 || month == 1 || month == 2 || month == 3 || month == 4 || month == 5 || month == 6 || month == 7 || month == 8 || month == 9) {
                month = '0'+month;
            }
            this.dateNow = date.getFullYear()+'-'+month+'-'+date.getDate();
            this.inputActivityLog.date = this.dateNow
            this.search(this.dateNow)
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            search(date) {
                let context = this;
                context.onLoad = true;
                Api(context, activityLog.index({search: context.inputActivityLog.search, date:context.inputActivityLog.date,})).onSuccess(function(response) {
                    context.dataActivityLog = response.data.data.data;  
                    // console.log(response.data.data)
                    context.table.show = true
                    context.btnExport.disabled = false
                }).onError(function(error) {         
                    if (error.response.status == 404) {
                        context.table.show = false
                        context.btnExport.disabled = true
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call();
            },
            exportExcel() {
                let context = this;
                Api(context, activityLog.export({date:context.inputActivityLog.date, token:localStorage.token})).onSuccess(function(response) {
                    context.dataActivityLog = response.data.data.data
                    console.log(response.data.data.data)
                    context.table.show = true
                }).call();
            },
            filter() {
                if(this.inputActivityLog.search != this.$route.query.search){
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.inputActivityLog.search == ''){
                            this.$router.push({path:'/report/activity-log', query:null})
                        }else{
                            this.$router.push({path:'/report/activity-log', query:{search:this.inputActivityLog.search}})
                        }
                        this.search(this.inputActivityLog.date)
                    }, 100);
                }
            },
            changeDate() {
                alert('tes')
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_table_column');
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('edit_table_column');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
